"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsersApi = void 0;
var runtime = __importStar(require("../runtime"));
var models_1 = require("../models");
/**
 * no description
 */
var UsersApi = /** @class */ (function (_super) {
    __extends(UsersApi, _super);
    function UsersApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * takes in the signature from a wallet to verify the public key.
     */
    UsersApi.prototype.addSolanaWalletRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("StandardUser", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/user/add-solana-wallet",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, models_1.AddWalletRequestBodyToJSON)(requestParameters.addWalletRequestBody),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * takes in the signature from a wallet to verify the public key.
     */
    UsersApi.prototype.addSolanaWallet = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.addSolanaWalletRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * takes in the signature from a wallet to verify the public key.
     */
    UsersApi.prototype.addWalletRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("StandardUser", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/user/add-wallet",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, models_1.AddWalletRequestBodyToJSON)(requestParameters.addWalletRequestBody),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * takes in the signature from a wallet to verify the public key.
     */
    UsersApi.prototype.addWallet = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.addWalletRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Allows an admin to debit or credit an arbitrary amount of dpay.
     */
    UsersApi.prototype.adminDebitOrCreditLevelPointsRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("GodAdmin", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/users/admin-debit-or-credit-level-points",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, models_1.AdminDebitOrCreditLevelPointRequestBodyToJSON)(requestParameters.adminDebitOrCreditLevelPointRequestBody),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Allows an admin to debit or credit an arbitrary amount of dpay.
     */
    UsersApi.prototype.adminDebitOrCreditLevelPoints = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.adminDebitOrCreditLevelPointsRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Bans or unbans a user from all communities
     */
    UsersApi.prototype.banOrUnbanUserFromAllCommunitiesRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Admin", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/users/ban-or-unban-user-from-all-communities",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, models_1.BanOrUnbanUserFromAllCommunitiesBodyToJSON)(requestParameters.banOrUnbanUserFromAllCommunitiesBody),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Bans or unbans a user from all communities
     */
    UsersApi.prototype.banOrUnbanUserFromAllCommunities = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.banOrUnbanUserFromAllCommunitiesRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Bans a user from a community, they will be unable to join until unbanned.
     */
    UsersApi.prototype.banUserFromCommunityRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Admin", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/users/ban-user-from-community",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, models_1.BanUserFromCommunityBodyToJSON)(requestParameters.banUserFromCommunityBody),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Bans a user from a community, they will be unable to join until unbanned.
     */
    UsersApi.prototype.banUserFromCommunity = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.banUserFromCommunityRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Used by users to change their passwords.
     */
    UsersApi.prototype.changePasswordRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("User", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/users/change-password",
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, models_1.ChangePasswordBodyToJSON)(requestParameters.changePasswordBody),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Used by users to change their passwords.
     */
    UsersApi.prototype.changePassword = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.changePasswordRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Used by GOD admins to change user\'s passwords.
     */
    UsersApi.prototype.changeUserPasswordRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling changeUserPassword.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("GodAdmin", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/users/{id}/change-password".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, models_1.ChangeUserPasswordBodyToJSON)(requestParameters.changeUserPasswordBody),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Used by GOD admins to change user\'s passwords.
     */
    UsersApi.prototype.changeUserPassword = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.changeUserPasswordRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Used by admins to disable a user
     */
    UsersApi.prototype.disableUserRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling disableUser.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Admin", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/users/{id}/disable".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Used by admins to disable a user
     */
    UsersApi.prototype.disableUser = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.disableUserRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Used by GOD admins to enable a user
     */
    UsersApi.prototype.enableUserRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling enableUser.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Admin", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/users/{id}/enable".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Used by GOD admins to enable a user
     */
    UsersApi.prototype.enableUser = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.enableUserRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Used by users to add or remove a business from their favorites list.
     */
    UsersApi.prototype.favoriteBusinessRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        if (requestParameters.businessId !== undefined) {
                            queryParameters['businessId'] = requestParameters.businessId;
                        }
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("User", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/users/favorite-business",
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Used by users to add or remove a business from their favorites list.
     */
    UsersApi.prototype.favoriteBusiness = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.favoriteBusinessRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Get user authentication hash for Atlas chat session.
     */
    UsersApi.prototype.getAtlasHashRaw = function () {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("User", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/users/atlas-hash",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.AtlasHashResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get user authentication hash for Atlas chat session.
     */
    UsersApi.prototype.getAtlasHash = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getAtlasHashRaw()];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get all communities a user is banned from
     */
    UsersApi.prototype.getBannedCommunitiesRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getBannedCommunities.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Admin", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/users/get-banned-communities/{id}".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.GetBannedCommunitiesResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get all communities a user is banned from
     */
    UsersApi.prototype.getBannedCommunities = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getBannedCommunitiesRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get the leaderboard nickname of the current user.
     */
    UsersApi.prototype.getLeaderboardNicknameRaw = function () {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("User", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/users/leaderboard-nickname",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.InlineResponse20019FromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get the leaderboard nickname of the current user.
     */
    UsersApi.prototype.getLeaderboardNickname = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getLeaderboardNicknameRaw()];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get information regarding the user\'s level and experience.
     */
    UsersApi.prototype.getLevelRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getLevel.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("User", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/users/level/{id}".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.AccountLevelFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get information regarding the user\'s level and experience.
     */
    UsersApi.prototype.getLevel = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getLevelRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     */
    UsersApi.prototype.getNftOwnershipsForUserRaw = function () {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/users/nft-ownerships",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.NftOwnershipInformationFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     */
    UsersApi.prototype.getNftOwnershipsForUser = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getNftOwnershipsForUserRaw()];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     */
    UsersApi.prototype.getProfileRaw = function () {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("User", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/users/profile",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.GetUserResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     */
    UsersApi.prototype.getProfile = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getProfileRaw()];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get the top 15 users, ranked by total experience.
     */
    UsersApi.prototype.getTopUsersByLeaderboardRankRaw = function () {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/users/top-leaderboard-ranks",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.InlineResponse20017FromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get the top 15 users, ranked by total experience.
     */
    UsersApi.prototype.getTopUsersByLeaderboardRank = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getTopUsersByLeaderboardRankRaw()];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     */
    UsersApi.prototype.getUserRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getUser.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Admin", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/users/{id}".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.GetUserResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     */
    UsersApi.prototype.getUser = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getUserRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get information regarding the user\'s community, their rank and points earned for the community.
     */
    UsersApi.prototype.getUserCommunityMembershipRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getUserCommunityMembership.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Admin", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/users/{id}/membership".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.GetUserCommunityMembershipResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get information regarding the user\'s community, their rank and points earned for the community.
     */
    UsersApi.prototype.getUserCommunityMembership = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getUserCommunityMembershipRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Given the user\'s email, will return the user\'s id
     */
    UsersApi.prototype.getUserIdRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.email === null || requestParameters.email === undefined) {
                            throw new runtime.RequiredError('email', 'Required parameter requestParameters.email was null or undefined when calling getUserId.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/users/id/{email}".replace("{".concat("email", "}"), encodeURIComponent(String(requestParameters.email))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.GetUserIdResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Given the user\'s email, will return the user\'s id
     */
    UsersApi.prototype.getUserId = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getUserIdRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get the leaderboard rank of the current user.
     */
    UsersApi.prototype.getUserLeaderboardRankRaw = function () {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("User", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/users/leaderboard-rank",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.InlineResponse20018FromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get the leaderboard rank of the current user.
     */
    UsersApi.prototype.getUserLeaderboardRank = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getUserLeaderboardRankRaw()];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * get user\'s current level lootbox mint record
     */
    UsersApi.prototype.getUserLevelLootboxMintRecordRaw = function () {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("User", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/user-lootbox-mint-record",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.LootboxMintRecordFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * get user\'s current level lootbox mint record
     */
    UsersApi.prototype.getUserLevelLootboxMintRecord = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getUserLevelLootboxMintRecordRaw()];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     */
    UsersApi.prototype.getUsersRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        if (requestParameters.type) {
                            queryParameters['type'] = requestParameters.type;
                        }
                        if (requestParameters.search !== undefined) {
                            queryParameters['search'] = requestParameters.search;
                        }
                        if (requestParameters.sort !== undefined) {
                            queryParameters['sort'] = requestParameters.sort;
                        }
                        if (requestParameters.order !== undefined) {
                            queryParameters['order'] = requestParameters.order;
                        }
                        if (requestParameters.limit !== undefined) {
                            queryParameters['limit'] = requestParameters.limit;
                        }
                        if (requestParameters.offset !== undefined) {
                            queryParameters['offset'] = requestParameters.offset;
                        }
                        if (requestParameters.numberOfAvailableOffsets !== undefined) {
                            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
                        }
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Admin", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/users",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.GetUsersResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     */
    UsersApi.prototype.getUsers = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getUsersRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     */
    UsersApi.prototype.getUsersLevelCsvRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        if (requestParameters.type) {
                            queryParameters['type'] = requestParameters.type;
                        }
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Admin", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/users/userslvl",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.GetUsersCsvResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     */
    UsersApi.prototype.getUsersLevelCsv = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getUsersLevelCsvRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Used to import new users
     */
    UsersApi.prototype.importUsersRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Admin", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/users/import",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, models_1.ImportUserBodyToJSON)(requestParameters.importUserBody),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.ImportUserResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Used to import new users
     */
    UsersApi.prototype.importUsers = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.importUsersRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Used to log in admins and merchants.
     */
    UsersApi.prototype.loginRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.type === null || requestParameters.type === undefined) {
                            throw new runtime.RequiredError('type', 'Required parameter requestParameters.type was null or undefined when calling login.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/login/{type}".replace("{".concat("type", "}"), encodeURIComponent(String(requestParameters.type))),
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, models_1.LoginBodyToJSON)(requestParameters.loginBody),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.TokenFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Used to log in admins and merchants.
     */
    UsersApi.prototype.login = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loginRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Used to log in standard users.
     */
    UsersApi.prototype.magicLoginRaw = function () {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Magic", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/login/magic",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.LoginResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Used to log in standard users.
     */
    UsersApi.prototype.magicLogin = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.magicLoginRaw()];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Used to register a standard user.
     */
    UsersApi.prototype.registerRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Magic", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/register",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, models_1.RegisterBodyToJSON)(requestParameters.registerBody),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.LoginResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Used to register a standard user.
     */
    UsersApi.prototype.register = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.registerRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Used to register a merchant user
     */
    UsersApi.prototype.registerMerchantRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Discriminated", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/register/merchant",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, models_1.CreateUserBodyToJSON)(requestParameters.createUserBody),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.GetUserResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Used to register a merchant user
     */
    UsersApi.prototype.registerMerchant = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.registerMerchantRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Used by users to remove their profile picture.
     */
    UsersApi.prototype.removeProfilePictureRaw = function () {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("User", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/users/remove-profile-picture",
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Used by users to remove their profile picture.
     */
    UsersApi.prototype.removeProfilePicture = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.removeProfilePictureRaw()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Removes a user from their current community
     */
    UsersApi.prototype.removeUserCommunityMembershipRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling removeUserCommunityMembership.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("User", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/users/{id}/remove-user-from-community".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Removes a user from their current community
     */
    UsersApi.prototype.removeUserCommunityMembership = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.removeUserCommunityMembershipRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * removes a wallet from the given account.
     */
    UsersApi.prototype.removeWalletRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("StandardUser", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/user/remove-wallet",
                                method: 'DELETE',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, models_1.RemoveWalletRequestBodyToJSON)(requestParameters.removeWalletRequestBody),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * removes a wallet from the given account.
     */
    UsersApi.prototype.removeWallet = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.removeWalletRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Reset isEjectedFromCommunity for a user.
     */
    UsersApi.prototype.resetIsEjectedRaw = function () {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("User", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/users/reset-community-ejection",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Reset isEjectedFromCommunity for a user.
     */
    UsersApi.prototype.resetIsEjected = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.resetIsEjectedRaw()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Unbans a user from a community.
     */
    UsersApi.prototype.unbanUserFromCommunityRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Admin", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/users/unban-user-from-community",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, models_1.BanUserFromCommunityBodyToJSON)(requestParameters.banUserFromCommunityBody),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Unbans a user from a community.
     */
    UsersApi.prototype.unbanUserFromCommunity = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.unbanUserFromCommunityRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Used by users to add, remove or update their default payment method.
     */
    UsersApi.prototype.updateDefaultPaymentRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        if (requestParameters.defaultPaymentMethodId !== undefined) {
                            queryParameters['defaultPaymentMethodId'] = requestParameters.defaultPaymentMethodId;
                        }
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("User", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/users/update-default-payment",
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Used by users to add, remove or update their default payment method.
     */
    UsersApi.prototype.updateDefaultPayment = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.updateDefaultPaymentRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Update the leaderboard nickname of the current user.
     */
    UsersApi.prototype.updateLeaderboardNicknameRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("User", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/users/leaderboard-nickname",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, models_1.UpdateNicknameBodyToJSON)(requestParameters.updateNicknameBody),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Update the leaderboard nickname of the current user.
     */
    UsersApi.prototype.updateLeaderboardNickname = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.updateLeaderboardNicknameRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     */
    UsersApi.prototype.updateNftOwnershipForUserRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        if (requestParameters.force !== undefined) {
                            queryParameters['force'] = requestParameters.force;
                        }
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("User", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/users/update-nft-ownerships-for-user",
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     */
    UsersApi.prototype.updateNftOwnershipForUser = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.updateNftOwnershipForUserRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Used by users to update their profile.
     */
    UsersApi.prototype.updateNotificationsRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("User", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/users/update-notifications",
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, models_1.UpdateNotificationsBodyToJSON)(requestParameters.updateNotificationsBody),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Used by users to update their profile.
     */
    UsersApi.prototype.updateNotifications = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.updateNotificationsRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Used by users to update their profile.
     */
    UsersApi.prototype.updateProfileRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("User", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/users/update-profile",
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, models_1.UpdateProfileBodyToJSON)(requestParameters.updateProfileBody),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Used by users to update their profile.
     */
    UsersApi.prototype.updateProfile = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.updateProfileRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Used by users to update their profile picture.
     */
    UsersApi.prototype.updateProfilePictureRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("User", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/users/update-profile-picture",
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, models_1.UpdateProfilePictureBodyToJSON)(requestParameters.updateProfilePictureBody),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Used by users to update their profile picture.
     */
    UsersApi.prototype.updateProfilePicture = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.updateProfilePictureRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Used by admins to change a user\'s type.
     */
    UsersApi.prototype.updateUserTypeRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updateUserType.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Discriminated", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/users/{id}/type".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, models_1.UpdateUserTypeBodyToJSON)(requestParameters.updateUserTypeBody),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Used by admins to change a user\'s type.
     */
    UsersApi.prototype.updateUserType = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.updateUserTypeRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return UsersApi;
}(runtime.BaseAPI));
exports.UsersApi = UsersApi;
